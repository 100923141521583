<template>
    <div>
        <div class="codeImgContainer">
            <img :src="imgInfo.url" class="codeImg">
            <div style="margin-top: 16px;color: #666;">扫码添加客服微信</div>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            imgInfo: {},
        };
    },

    mounted() {
        this.getCodeImg();
    },

    methods: {
        getCodeImg() {
            this.imgInfo = {};
            this.$httpBack.file.showOne({ resourceType: 5 }).then((res) => {
                if (res.code == 0) {
                    this.imgInfo = res.data;
                }
            })
        }
    },
};
</script>
<style>
.codeImg {
    width: 220px;
    height: 220px;
}

.codeImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}
</style>