<template>
    <div>
        <!-- 菜单 -->
        <!-- <div class="menuContainer">
            <div v-for="item in menuList" :key="item.value"
                :class="item.value == currentMenuValue ? 'menuSelect' : 'menu'" @click="currentMenuValue = item.value">
                {{ item.label }}
            </div>
        </div> -->
        <el-tabs v-model="currentMenuValue">
            <el-tab-pane v-for="item in menuList" :key="item.value" :label="item.label" :name="item.name"></el-tab-pane>
        </el-tabs>
        <!-- 基本设置 -->
        <basic-setup v-if="currentMenuValue == 'basicSetup'"></basic-setup>
        <!-- 消息推送 -->
        <msg-push-setup v-if="currentMenuValue == 'pushMsg'"></msg-push-setup>
        <!-- 账号设置 -->
        <account-setup v-if="currentMenuValue == 'accountSetup'" @updateUserInfo="updateUserInfo"></account-setup>
        <!-- 联系客服 -->
        <customer-service v-if="currentMenuValue == 'customerService'"></customer-service>
    </div>
</template>

<script>
import basicSetup from './basicSetup.vue';
import msgPushSetup from "./msgPushSetup.vue";
import accountSetup from "./accountSetup.vue";
import customerService from "./customerService.vue";
export default {
    components: {
        basicSetup,
        msgPushSetup,
        accountSetup,
        customerService
    },
    data() {
        return {
            currentMenuValue: 'basicSetup',
            menuList: [
                { label: "基本设置", name: 'basicSetup' },
                { label: "推送消息", name: 'pushMsg' },
                { label: "账号设置", name: 'accountSetup' },
                { label: "联系客服", name: 'customerService' },
            ],
        };
    },

    mounted() {
        let setupMenu = this.$store.state.setupMenu;
        if (setupMenu) {
            this.currentMenuValue = setupMenu;
            this.$store.commit("saveSetupMenu", null);
        }
    },

    methods: {
        updateUserInfo() {
            this.$emit("updateUserInfo", "");
        }
    },
};
</script>

<style scoped>
.bodyContainer {
    padding: 40px 32px;
    display: flex;
}

.menuSelect {
    font-weight: 500;
    font-size: 14px;
    color: #0077FF;
    cursor: pointer;
    margin-right: 52px;
    border-bottom: 2px #0077FF solid;
    height: 36px;
    line-height: 36px;
}

.menu {
    font-weight: 400;
    font-size: 14px;
    color: #2E3742;
    cursor: pointer;
    margin-right: 52px;
    height: 36px;
    line-height: 36px;
}

.menuContainer {
    display: flex;

    align-items: center;
    border-bottom: 1px solid #DCE0E7;
}
</style>