<template>
    <div class="">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" class="formContainer"
            label-position="left">
            <div class="leftContainer">
                <el-tooltip class="item" effect="dark" content="开播检测频率最小值为10秒，挂机建议30秒以上" placement="top">
                    <el-form-item label="检测频率" prop="detectionFrequency">
                        <el-input style="width: 250px;" v-model.number="dataForm.detectionFrequency"
                            placeholder="请输入"></el-input>
                        <span style="margin-left: 15px;">秒</span>
                    </el-form-item>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="默认使用flv即可，录不上再切换" placement="top">
                    <el-form-item label="直播源" prop="streamingSource">
                        <el-select style="width: 250px;" v-model="dataForm.streamingSource" placeholder="请选择直播源">
                            <el-option v-for="(item, index) in ['m3u8源', 'flv源']" :key="item" :label="item"
                                :value="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="录制到指定时长后，将停止录制" placement="top">
                    <el-form-item label="限制时长" prop="limitTime">
                        <div style="display: flex; margin-left: 10px;">
                            <el-slider v-model="dataForm.limitTime" :marks="marks" :max="600" style="width: 250px;"
                                :show-tooltip="false" @change="val => sliderChange(val, 'limitTime')">
                            </el-slider>
                            <div style="margin-left: 10px;color: red;">{{ dataForm.limitTime ?
                                (dataForm.limitTime % 60 == 0 ? dataForm.limitTime / 60 + '小时' :
                                    parseInt(dataForm.limitTime / 60) + '小时' + dataForm.limitTime % 60 + '分') :
                                '无限制' }}</div>
                        </div>
                    </el-form-item>
                </el-tooltip>

                <!-- <el-form-item label="自动分段" prop="autoSubsection" style="margin-top: 36px;">
                    <el-tooltip class="item" effect="dark" content="主播进入PK模式的时候将自动分段" placement="top">
                        <el-switch v-model="dataForm.autoSubsection" active-color="#13ce66" inactive-color="#DCE0E7">
                        </el-switch>
                    </el-tooltip>
                </el-form-item> -->
                <el-form-item label="隐藏时长" prop="hiddenTime" style="margin-top: 36px;">
                    <el-tooltip class="item" effect="dark" content="录制时不显示已录制时长" placement="top">
                        <el-switch v-model="dataForm.hiddenTime" active-color="#13ce66" inactive-color="#DCE0E7">
                        </el-switch>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="在线人数" prop="showOnlineNum">
                    <el-tooltip class="item" effect="dark" content="录制时不显示在线人数" placement="top">
                        <el-switch v-model="dataForm.showOnlineNum" active-color="#13ce66" inactive-color="#DCE0E7">
                        </el-switch>
                    </el-tooltip>
                </el-form-item>
                <!-- <el-form-item label="开播提示" prop="startTip">
                    <el-tooltip class="item" effect="dark" content="主播开播时，播放语音提示，可替换kaibo.mp3为您喜欢的语音" placement="top">
                        <el-switch v-model="dataForm.startTip" active-color="#13ce66" inactive-color="#DCE0E7">
                        </el-switch>
                    </el-tooltip>
                </el-form-item> -->
            </div>
            <div class="rightContainer">
                <el-tooltip class="item" effect="dark" :content="dataForm.saveLocation" placement="top">
                    <el-form-item label="保存位置" prop="saveLocation">
                        <el-input style="width: 250px;" v-model="dataForm.saveLocation" placeholder="请输入保存位置"
                            @focus="checkfilebox" ref="checkfilebox"></el-input>
                    </el-form-item>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="按照指定的时长给视频分段，达到设置值后重新录制" placement="bottom">
                    <el-form-item label="时长分段" prop="subsectionTime" style="margin-top: 84px;">
                        <div style="display: flex; margin-left: 10px;">
                            <el-slider v-model="dataForm.subsectionTime" :marks="marks" :max="600" style="width: 250px;"
                                :show-tooltip="false" @change="val => sliderChange(val, 'subsectionTime')">
                            </el-slider>
                            <div style="margin-left: 10px;color: red;">{{ dataForm.subsectionTime ?
                                (dataForm.subsectionTime % 60 == 0 ? dataForm.subsectionTime / 60 + '小时' :
                                    parseInt(dataForm.subsectionTime / 60) + '小时' + dataForm.subsectionTime % 60 + '分') :
                                '无限制' }}</div>
                        </div>
                    </el-form-item>
                </el-tooltip>
                <!-- <el-tooltip class="item" effect="dark" content="录制到指定大小后，将停止录制" placement="top">
                    <el-form-item label="限制大小" prop="limitSize" style="margin-top: 84px;">
                        <div style="display: flex; margin-left: 10px;">
                            <el-slider v-model="dataForm.limitSize" :marks="sizeMarks" :max="10240"
                                style="width: 250px;" @change="val => sliderChange(val, 'limitSize')">
                            </el-slider>
                            <div style="margin-left: 10px;color: red;">{{ dataForm.limitSize ?
                                keepTwoDecimalPlaces(dataForm.limitSize / 1024) + 'G' : '无限制' }}</div>
                        </div>
                    </el-form-item>
                </el-tooltip> -->
                <!-- <el-tooltip class="item" effect="dark" content="按照指定的大小给视频分段，达到设置值后重新录制" placement="top">
                    <el-form-item label="分段大小" prop="subsectionSize" style="margin-top: 36px;">
                        <div style="display: flex; margin-left: 10px;">
                            <el-slider v-model="dataForm.subsectionSize" :marks="sizeMarks" :max="10240"
                                style="width: 250px;" @change="val => sliderChange(val, 'subsectionSize')">
                            </el-slider>
                            <div style="margin-left: 10px;color: red;">{{ dataForm.subsectionSize ?
                                keepTwoDecimalPlaces(dataForm.subsectionSize / 1024) + 'G' : '无限制' }}</div>
                        </div>
                    </el-form-item>
                </el-tooltip> -->

                <el-form-item label="隐藏大小" prop="hiddenSize" style="margin-top: 36px;">
                    <el-tooltip class="item" effect="dark" content="录制时不显示文件大小" placement="top">
                        <el-switch v-model="dataForm.hiddenSize" active-color="#13ce66" inactive-color="#DCE0E7">
                        </el-switch>
                    </el-tooltip>
                </el-form-item>

                <div style="text-align: right;margin-top: 100px;">
                    <el-button style="margin-right: 50px;" type="success" size="small" @click="submit">应用</el-button>
                </div>
            </div>


        </el-form>

    </div>
</template>

<script>
import myUtils from '../../../utils/utils';
export default {
    data() {
        var checkDetectionFrequency = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('检测频率不能为空'));
            }
            setTimeout(() => {
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'));
                } else {
                    if (value < 10) {
                        callback(new Error('必须大于10'));
                    } else {
                        callback();
                    }
                }
            }, 500);
        };
        return {
            dataForm: {
                detectionFrequency: 30, // 检测频率
                streamingSource: 0, // 直播源
                limitTime: 0, // 限制时长,0表示无限制，单位：分钟
                subsectionTime: 0, // 分段时长,0表示无限制，单位：分钟
                limitSize: 0, // 限制大小,0表示无限制，单位：G
                subsectionSize: 0, // 分段大小,0表示无限制，单位：G
                autoSubsection: false, // 自动分段
                hiddenTime: false, // 隐藏时长
                showOnlineNum: false, // 显示在线人数
                saveLocation: "", // 保存的路径
                hiddenSize: false, // 隐藏大小
                startTip: false, // 开播提示
                id: "", // ID
                serialNumber: "", // 客户端序列号
                isRocord: 0,
            },
            marks: {
                0: '无限制',
                120: '2h',
                240: '4h',
                360: '6h',
                480: '8h',
                600: '10h',
            },
            sizeMarks: {
                0: '无限制',
                2048: '2G',
                4096: '4G',
                6144: '6G',
                8192: '8G',
                10240: '10G',
            },
            dataRule: {
                detectionFrequency: [
                    { required: true, validator: checkDetectionFrequency, trigger: 'blur' }
                ],
                saveLocation: [
                    { required: true, message: '保存路径不能为空', trigger: 'blur' },
                ]
            },
        };
    },

    mounted() {
        this.getBasinSetupInfo();
    },

    methods: {
        checkfilebox() {
            this.$httpClient.setup.checkfilebox({}).then(res => {
                if (res.code == 0 && res.data) {
                    this.dataForm.saveLocation = res.data;
                }
            });
            this.$refs["checkfilebox"].blur();
        },
        // 获取基本设置信息
        getBasinSetupInfo() {
            this.$nextTick(() => {
                this.$refs["dataForm"].resetFields();

                this.$httpClient.setup.getmodel({}).then((res) => {
                    if (res.code == 0) {
                        this.dataForm.id = res.data.Id;
                        this.dataForm.serialNumber = res.data.SerialNumber;
                        this.dataForm.detectionFrequency = res.data.DetectionFre;
                        this.dataForm.streamingSource = res.data.LiveSource;
                        this.dataForm.autoSubsection = res.data.IsSubection == 0 ? false : true;
                        this.dataForm.hiddenTime = res.data.HideDuration == 0 ? false : true;
                        this.dataForm.showOnlineNum = res.data.OnlineNumber == 0 ? false : true;
                        this.dataForm.saveLocation = res.data.SavePath;
                        this.dataForm.hiddenSize = res.data.HideSize == 0 ? false : true;
                        this.dataForm.startTip = res.data.LiveNotice == 0 ? false : true;
                        this.dataForm.isRocord = res.data.IsRocord;

                        if (res.data.LimitType == 1) {
                            this.dataForm.limitTime = parseInt(res.data.LimitValue);
                        } else if (res.data.LimitType == 2) {
                            this.dataForm.subsectionTime = parseInt(res.data.LimitValue);
                        } else if (res.data.LimitType == 3) {
                            this.dataForm.limitSize = parseInt(res.data.LimitValue);
                        } else if (res.data.LimitType == 4) {
                            this.dataForm.subsectionSize = parseInt(res.data.LimitValue);
                        }


                    }

                });
            })


        },
        // 提交
        submit() {
            this.$refs["dataForm"].validate((valid) => {
                if (valid) {
                    let requestData = {};
                    requestData.Id = this.dataForm.id;
                    requestData.SerialNumber = this.dataForm.serialNumber;
                    requestData.DetectionFre = this.dataForm.detectionFrequency;
                    requestData.LiveSource = this.dataForm.streamingSource;
                    requestData.IsRocord = this.dataForm.isRocord;
                    requestData.IsSubection = this.dataForm.autoSubsection ? 1 : 0;
                    requestData.HideDuration = this.dataForm.hiddenTime ? 1 : 0;
                    requestData.OnlineNumber = this.dataForm.showOnlineNum ? 1 : 0;
                    requestData.SavePath = encodeURI(this.dataForm.saveLocation);
                    requestData.HideSize = this.dataForm.hiddenSize ? 1 : 0;
                    requestData.LiveNotice = this.dataForm.startTip ? 1 : 0;
                    requestData.LimitType = 0;
                    requestData.LimitValue = 0;
                    if (this.dataForm.limitTime > 0) {
                        requestData.LimitType = 1;
                        requestData.LimitValue = this.dataForm.limitTime;
                    } else if (this.dataForm.subsectionTime > 0) {
                        requestData.LimitType = 2;
                        requestData.LimitValue = this.dataForm.subsectionTime;
                    } else if (this.dataForm.limitSize > 0) {
                        requestData.LimitType = 3;
                        requestData.LimitValue = this.dataForm.limitSize;
                    } else if (this.dataForm.subsectionSize > 0) {
                        requestData.LimitType = 4;
                        requestData.LimitValue = this.dataForm.subsectionSize;
                    }

                    this.$httpClient.setup.updatemodel(requestData).then((res) => {
                        if (res && res.code == 0) {

                            if (this.dataForm.isRocord == 1) {
                                this.$confirm('当前正在录制中，等待下次重新录制后修改的配置才会生效。', '提示', {
                                    confirmButtonText: '确定',
                                    showCancelButton: false
                                });
                            } else {
                                this.$message.success("修改成功");
                            }
                        } else {
                            this.$message.error("系统异常，请联系客服");
                        }
                    })
                }
            })
        },
        // 精确两位小数
        keepTwoDecimalPlaces(data) {
            return myUtils.retainDecimals(data);
        },
        // 滑块值改变回调
        sliderChange(val, type) {
            this.dataForm.limitTime = 0;
            this.dataForm.subsectionTime = 0;
            this.dataForm.limitSize = 0;
            this.dataForm.subsectionSize = 0;
            if (type == 'limitTime') {
                this.dataForm.limitTime = val;
            } else if (type == 'subsectionTime') {
                this.dataForm.subsectionTime = val;
            } else if (type == 'limitSize') {
                this.dataForm.limitSize = val;
            } else if (type == 'subsectionSize') {
                this.dataForm.subsectionSize = val;
            }
        }
    },
};
</script>
<style scoped>
.submitBtn {
    position: absolute;
    right: 60px;
}

.rightContainer {
    min-width: 450px;
}

.leftContainer {
    min-width: 420px;
}

.formContainer {
    display: flex;
}
</style>